import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'

const Rentmap = ({ location }) => {
    const iframeOrigin = 'https://www.kontor.com/public/rentmap'
    return (
        <Layout gutters={false} container={false} activePage="/london-rentmap" location={location}>
             <Header
                title={`London Rentmap | Kontor`}
                description=""
            />
            <iframe
                title="Rentmap"
                src={iframeOrigin}
                loading='lazy'
                width="100vw"
                height="100vh"
                className="block"
                allow="clipboard-read"
                style={{
                    marginBottom: '15rem',
                    height: '100vh',
                    width: '100vw',
                    border: 'none',
                    paddingTop: '100px'
                }}
            />
        </Layout>
    )
}

export default Rentmap;